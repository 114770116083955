import Vue from 'vue';
import Router from 'vue-router';
import AuthService from '@/auth';
import middlewareCoR from '@/router/middleware/middlewareCoR';
import routes from '@/router/routes';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'active',
  mode: 'history',
  routes: routes(),
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach(async (to, from, next) => {
  if (from.name === null) {
    await AuthService.loginByAccessToken().catch(() => {});
  }

  document.title = to.meta.title ? `${to.meta.title} | ОК` : 'ОКw';

  if (to.matched.some((record) => record.meta.middleware)) {
    const { middleware } = to.meta;

    const context = {
      to,
      from,
      next,
      store,
    };

    return middleware[0]({
      ...context,
      nextMiddleware: middlewareCoR(context, middleware, 1),
    });
  }

  return next();
});

export default router;
