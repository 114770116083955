import store from './store';
import urls from './router/urls';
import { customAxios } from './plugins/axios';

function getTokenData(accessToken) {
  const payload = accessToken.split('.')[1];

  return JSON.parse(atob(payload));
}

async function setAuthData(accessToken) {
  const { email, id, services } = getTokenData(accessToken);

  // eslint-disable-next-line max-len
  const { roles } = services.filter(({ serviceUrl }) => process.env.VUE_APP_SERVICE_URL === serviceUrl)[0];

  const auth = {
    accessToken,
    user: {
      email,
      id,
      roles,
    },
  };

  store.commit('set', ['auth', auth]);

  localStorage.setItem('accessToken', accessToken);
}

export default class {
  static getBearer() {
    return `Bearer ${store.state.auth.accessToken}`;
  }

  static isAccessTokenExpired(accessToken) {
    const tokenData = getTokenData(accessToken);
    const accessTokenExp = tokenData.exp - 10;

    return accessTokenExp <= Math.floor(new Date().getTime() / 1000);
  }

  static async loginByAccessToken() {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      if (this.isAccessTokenExpired(accessToken)) {
        this.logout();
      } else {
        await setAuthData(accessToken);
      }
    }
  }

  static async loginByEmailAndPassword({ email, password }) {
    const data = {
      email,
      password,
    };

    const requestConfig = {};

    if (process.env.VUE_APP_X_ORIGIN_DEV) {
      requestConfig.headers = {
        'X-Origin-Dev': process.env.VUE_APP_X_ORIGIN_DEV,
      };
    }

    const response = await customAxios.post(urls.urlLogin, data, requestConfig);

    await setAuthData(response.data.data.token);
  }

  static logout() {
    this.resetAuthData();
  }

  // static async refreshTokens() {
  //   try {
  //
  //   } catch (e) {
  //     this.resetAuthData();
  //   }
  // }

  static resetAuthData() {
    const auth = {
      accessToken: null,
      user: {
        email: null,
        id: null,
        roles: [],
      },
    };

    store.commit('set', ['auth', auth]);

    localStorage.removeItem('accessToken');
  }
}
