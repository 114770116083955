import authorized from './middleware/authorized';
import publicAccess from './middleware/publicAccess';
import serviceAdmin from './middleware/serviceAdmin';

export default function () {
  return [
    {
      children: [
        // Реестры
        {
          children: [
            {
              component: () => import('../views/admin/reestrs/List.vue'),
              meta: {
                label: 'Реестры',
                middleware: [
                  authorized,
                  // serviceAdmin,
                ],
                title: 'Реестры',
              },
              name: 'admin.reestrs.list',
              path: '',
            },
          ],
          component: () => import('../views/admin/reestrs/Index.vue'),
          path: 'reestrs/',
        },
        // Чеки
        {
          children: [
            {
              component: () => import('../views/admin/orders/Detail.vue'),
              meta: {
                label: 'Просмотр чека',
                middleware: [
                  authorized,
                  // serviceAdmin,
                ],
              },
              name: 'admin.orders.detail',
              path: ':id',
            },
            {
              component: () => import('../views/admin/orders/List.vue'),
              meta: {
                label: 'Чеки',
                middleware: [
                  // serviceAdmin,
                  authorized,
                ],
                title: 'Чеки',
              },
              name: 'admin.orders.list',
              path: '',
            },
          ],
          component: () => import('../views/admin/orders/Index.vue'),
          path: 'orders/',
        },
        // Настройки
        {
          children: [
            {
              component: () => import('../views/admin/settings/General.vue'),
              meta: {
                label: 'Настройки',
                middleware: [
                  serviceAdmin,
                ],
                title: 'Настройки',
              },
              name: 'admin.settings.general',
              path: '',
            },
          ],
          component: () => import('../views/admin/settings/General.vue'),
          path: 'settings/',
        },
      ],
      component: () => import('../containers/TheContainer.vue'),
      path: '/admin',
    },
    // Главная страница
    {
      component: () => import('../views/Index.vue'),
      meta: {
        middleware: [
          authorized,
        ],
      },
      name: 'index',
      path: '/',
    },
    // Вход в личный кабинет
    {
      component: () => import('../views/Login.vue'),
      meta: {
        middleware: [
          publicAccess,
        ],
        title: 'Вход',
      },
      name: 'login',
      path: '/login',
    },
    // Выход из личного кабинета
    {
      component: () => import('../views/admin/Logout.vue'),
      meta: {
        middleware: [
          authorized,
        ],
      },
      name: 'admin.logout',
      path: '/admin/logout',
    },
  ];
}
