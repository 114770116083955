function middlewareCoR(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextMiddlewareCoR = middlewareCoR(context, middleware, index + 1);

    nextMiddleware({ ...context, nextMiddleware: nextMiddlewareCoR });
  };
}

export default middlewareCoR;
