import 'core-js/stable';
import Vue from 'vue';
import './plugins/axios';
import CoreuiVue from '@coreui/vue';
import VCalendar from 'v-calendar';
import App from './App.vue';
import router from './router';
import icons from './assets/icons/icons';
import store from './store/index';

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(VCalendar);
Vue.prototype.$log = console.log.bind(console);

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  icons,
  components: {
    App,
  },
  template: '<App/>',
});
